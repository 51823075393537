// Colors ===========================

$black:      #000;
$new_pink:   #f72585;
$new_purple: #7209b7;
$new_blue_1: #3a0ca3;
$new_blue_2: #4361ee;
$new_blue_3: #4cc9f0;
$dark_grey:  #2f2f2f;
$light_grey: #4f4f4f;
$back_grey:  #efefef;
$green:      #79F82D;
$dark_green: #2B7400;
$red:        #FF0D00;
$white:      #fff;

$gradient: linear-gradient(315deg, $new_blue_2 0%, $new_blue_3 100%);
$gradient_alt: linear-gradient(315deg, $new_blue_3 0%, $new_blue_2 100%);

// Font ===============================

$font-family-1: mr-eaves-modern, sans-serif;
$font-family-2: brandon-grotesque, sans-serif;;

// Grid ===============================

$grid-max-width:           1140px;
$grid-min-width:           0px;

// Responsive Settings ================

$document-width:          $grid-max-width;
$min-device-width:        320px;
$mobile-device-width:     600px;
$tablet-device-width:     768px;
$max-device-width:        2880px;

// Video Info =========================

$video-ratio:             calc(6 / 1);
$video-width:             100vw;
$video-height:            calc($video-width / $video-ratio);
$video-width-m:           1200px;
$video-height-m:          200px;

// Grid Mixin =========================

@mixin respond($media) {
  @if $media == all-phones {
    @media only screen and (max-width: $mobile-device-width) { @content; }
  }
  
  @else if $media == portrait-tablets {
    @media only screen and (max-width: $tablet-device-width) { @content; }
  }
  
  @else if $media == tablets {
    @media only screen and (min-width: $tablet-device-width) and (max-width: $document-width) { @content; }
  }
  
  @else if $media == print {
    @media print { @content; }
  }
  
  @else {
    @media only screen and (#{$media}) { @content; }
  }
}

// Site Styles ===========================

@import url("https://use.typekit.net/yrv2adp.css");

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: $font-family-1;
  font-style: normal;
  font-weight: 300;
  height: 100vh;
  background: $new_blue_2;
}

*, *:before, *:after { box-sizing: border-box; }

h1, h2, h3 {
  font-family: $font-family-2;
  font-style: normal;
  font-weight: 700;
  padding: 0;
  margin: 0 0 2rem 0;
  color: $dark_grey;
  letter-spacing: 0.05em;
}

h2 {
  color: $new_blue_2;
  text-transform: uppercase;
}

h3 {
  font-weight: 100;
  font-style: normal;
}

p {
  padding: 0;
  margin: 0 0 2rem 0;
  line-height: 1.35em;
  color: $dark_grey;
  font-size: 18px;
}

// Grid Layout ---------------------------

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-auto-flow: column;
  grid-gap: 0;
  
  @include respond(portrait-tablets) { grid-template-columns: 1fr; }
  @include respond(all-phones) { grid-template-columns: 1fr; }
}

// Button ---------------------------

.btn {
  background: $gradient;
  height: 3em;
  transition: all .25s ease-in-out;
  cursor: pointer;
  display: block;
  max-width: fit-content;

  &:hover {
    background: $gradient_alt;
    transition: all .15s ease-in-out;
  }

  a {
    color: $white;
    text-decoration: none;
    line-height: 3em;
    height: 3em;
    font-weight: 400;
    padding: 0 2em;
    display: block;
    border: none;

    &:hover { color: $white; }
    
    .fas,
    .fab,
    .fad { margin-left: 0.5rem; }
  }
}

// Text Gradient  ---------------------------

.text_gradient {
  background: $gradient_alt;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

// Content Styles ===========================

.outer_wrapper {
  padding: 0;
  margin: 0;
  width: 100%;
  
  .container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    overflow-y: auto;
    width: 100%;

    header, footer, section, article {
      display: block;
      width: 100%;
      padding: 2em 2em 0 2em;
    }
    
    // Header ---------------------------
    
    header {
      padding: 0;
      margin: 0;
      height: $video-height;
      width: $video-width;
      overflow: hidden;
      position: relative;

      @include respond(all-phones) {
        height: $video-height-m;
        width: 100vw;
      }

      .video_placeholder {
        position: relative;
        top: 0;
        font-size: 0;

        img {
          width: 100%;
          height: auto;
          position: relative;

          @include respond(all-phones) {
            height: $video-height-m;
            width: $video-width-m;
            top: 0;
            left: calc(50% - ($video-width-m / 2));
          }
        }
      }

      iframe {
        padding: 0;
        margin: 0;
        position: absolute;
        height: $video-height;
        width: $video-width;
        top: calc(50% - ($video-height / 2));
        left: 0;

        @include respond(all-phones) {
          height: $video-height-m;
          width: $video-width-m;
          top: 0;
          left: calc(50% - ($video-width-m / 2));
        }
      }
      
      .header_time {
        position: absolute;
        top: 15px;
        right: 15px;
        color: $white;
        font-size: 0.8em;
        padding: 0;
        margin: 0;
        
        @include respond(all-phones) {
          text-align: center;
          width: 100%;
          right: 0;
          top: inherit;
          bottom: 15px;
        }
      }
      
      a {
        display: block;
        position: absolute;
        top: calc(50% - 37.5px); //half of height
        left: calc(50% - 87.5px); //half of width

        h1 {
          font-size: 0;
          margin: 0;
          
          img {
            display: block;
            width: 175px;
            height: 75px;
            filter:
              drop-shadow(0 0 15px rgba($new_blue_3, 0.95))
              drop-shadow(0 0 30px rgba($new_blue_3, 0.95))
              drop-shadow(0 0 45px rgba($new_blue_3, 0.95))
              drop-shadow(0 0 2px rgba($black, .25));
          }
        }
      }
    }
    
    // About ---------------------------
    
    section.about {
      background: $back_grey;
      display: flex;
      justify-content: center;
      
      .wrapper {
        @extend .grid-container;
        width: 100%;
        max-width: $grid-max-width;
        
        .side_1,
        .side_2 {
          display: grid;
          justify-items: center;
          align-items: center;
          
          @include respond(portrait-tablets) {
            grid-auto-rows: 100%;
            grid-column: 1 !important;
          }
          
          @include respond(all-phones) { 
            grid-auto-rows: 100%;
            grid-column: 1 !important;
          }
          
          .content { position: relative; }
        }
        
        .side_1 {
          grid-column: 1;

          @include respond(all-phones) {
            h2, h3, p {
              text-align: center;
            }
            
            .btn {
              width: 100%;
              display: block;
              max-width: 100%;
            }
          }

          h2 { margin-bottom: 0; }
          h3 { margin-bottom: 0.5rem; }
          .content { text-align: left; }
        }

        .side_2 {
          grid-column: 2;
          align-items: end;
          justify-items: end;
          
          @include respond(portrait-tablets) { justify-items: center; }
          @include respond(all-phones) { justify-items: center; }
                    
          img {
            width: 100%;
            //max-width: 500px;
            height: auto;
            display: block;
            position: relative;
            left: 5px;
          }
        }
      }
    }
    
    // Portfolio ---------------------------
    
    section.portfolio {
      background: $white;
      display: flex;
      justify-content: center;
      
      .wrapper {
        @extend .grid-container;
        grid-template-columns: 1fr;
        
        .side_1,
        .side_2 {
          display: grid;
          justify-items: center;
          align-items: center;
          grid-auto-rows: 100%;
          grid-column: 1;
        }
        
        .side_2 {          
          .content {
            position: relative;
            width: 100%;
            max-width: 700px;

            img {
              display: block;
              padding: 42px 0 2em 0;
            }

            .citation {
              position: absolute;
              top: 0;
              width: 100%;
              right: 0;
              background: linear-gradient(90deg, $new_blue_1, $new_blue_2 );
              padding: 5px;
              height: 42px;
        
              h4 {
                display: block;
                font-size: 0.8em;
                font-weight: 700;
                color: $white;
                margin: 0;
              }
        
              cite {
                display: block;
                font-size: 0.7em;
                color: $white;
                margin: 2px 0 0 0;
              }
            }
          }
        }

        .side_1 {          
          .content {
            text-align: center;
            max-width: 800px;
            
            h2 { margin-bottom: 0.5rem; }

            p { max-width: 800px; }
            
          }
        }
      }
    }
    
    // Contact ---------------------------
    
    section.contact {
      background: $new_pink;
      background: $gradient_alt;
      display: flex;
      justify-content: center;
      padding: 2em;

      .grecaptcha-badge { 
        visibility: hidden;
      }
      
      .wrapper {
        width: 100%;
        max-width: 800px;
        
        .content {
          display: block;
          text-align: center;
          
          @include respond(portrait-tablets) {
            display: block;
            text-align: center;            
          }
          
          @include respond(all-phones) {
            display: block;
            text-align: center;
          }
          
          h2 {
            color: $white;
            line-height: 3rem;
            height: 3rem;
            margin: 0 0 0.5em 0;
            display: block;
            
            @include respond(portrait-tablets) {
              line-height: normal;
              height: auto;
              margin: 0 auto 0.75rem auto;
            }
            
            @include respond(all-phones) {
              line-height: normal;
              height: auto;
              margin: 0 auto 0.75rem auto;
            }
          }

          p { color: $white; }
        }
     }
   } 
    
    // Footer ---------------------------

    footer {
      text-align: center;
      padding: 0;
      margin: 0;
      background: rgba($new_blue_2, .75);
      
      .content {
        margin: 4rem auto 2rem auto;
        max-width:  360px;
        
        img {
          max-width: 100px;
          display: block;
          margin: 0 auto 2rem auto;
        }
        
        p {
          color: $white;
          margin-bottom: 0.5rem;
          font-size: 14px;
          line-height: 17px;
          
          &.googlerecaptcha {
            margin-bottom: 2rem;

            a {
              cursor: pointer;
              text-decoration: underline;
              color: $white;

              &:hover {
                text-decoration: underline;
                color: $white;
              }
            }
          }
        }
        
        .social_icons {
          padding: 0 0 2rem 0;
          margin: 0;
          
          @include respond(all-phones) { text-align: center; }
          
          li {
            display: inline-block;
            margin-left: 1rem;
            
            &:first-child { margin-left: 0; }
            
            a {
              display: block;
              color: $white;
              font-size: 1.5em;
              line-height: 2em;
              
              &:hover { color: $new_blue_3; }
              &:after { display: none; }
            }
          }
        }
      }
    }
  }
}


// Carousel ===========================

.carousel {
  &.carousel-slider {
    .control-arrow {

      &:hover {
        background: rgba($dark_grey, 0.05);
        background: linear-gradient(0deg, rgba($dark_grey, 0) 0%, rgba($dark_grey, 0.05) 50%, rgba($dark_grey, 0) 100%); 
      }

      &.control-next {
        &::before { 
          border-left: 8px solid $dark_grey;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
        }
      }

      &.control-prev {
        &::before { 
          border-right: 8px solid $dark_grey;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
        }
      }
    }
  }
}

// Contact Form ===========================

#contact-form {
  border: 1px solid rgba($white, .5);
  color: $white;
  padding: 20px;
  background: rgba($white, .5);

  .contact_form_wrapper {
    &.inactive { display: none; }
  }

  input,
  textarea {
    width: 100%;
    border: 0;
    padding: 0 10px;
    margin-top: 15px;
    font-family: $font-family-1;
    font-size: 1em;
    background: $white;
    border: none;
    color: $dark_grey;
    display: block;

    &:focus,
    &:focus-visible {
      outline: 2px solid $new_blue_2;
    }
  }

  .contact_row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; 
    gap: 10px 10px;

    @include respond(all-phones) {
      display: block;
    }

    input {
      line-height: 3em;
      height: 3em;
      margin-top: 0;

      @include respond(all-phones) { margin-top: 15px !important; }
      &:first-child { margin-top: 0; }
    }
  }

  textarea {
    height: 200px;
    resize: none;
    padding: 10px;
  }

  button {
    width: 100%;
    border: 0;
    cursor: pointer;
    background: $white;
    color: rgba($light_grey, .35);
    font-size: 1em;
    font-family: $font-family-1;
    text-decoration: none;
    line-height: 3em;
    height: 3em;
    font-weight: 400;
    padding: 0 2em;
    display: block;
    margin-top: 15px;
    pointer-events: none;

    &.active {
      pointer-events: all;
      background: $gradient;
      color: $white;
    }

    &:hover {
      background: $gradient_alt;
      color: $white;
    }

    .fas,
    .fab,
    .fad { margin-left: 0.5rem; }
  }

  .btn {
    max-width: 100%;
    margin-top: 15px;

    &.inactive {
      background: $green;
      pointer-events: none;
      
      a {
        color: $dark_green;
        pointer-events: none;
        width: 100%;

        .fa-user:before {
          content: "\f4fc";
        }
      }
    }
  }

  .errorMessage {
    height: 1.5em;
    line-height: 1.5em;
    display: block;
    text-align: left;
    padding-top: 5px;
    font-size: 14px;
    font-weight: 500;
    color: $red;

    .fas,
    .fab,
    .fad {
      margin-right: 0.15rem;
      color: $red;
      position: relative;
      //top: -.05em;
    }
  }

  [name=name] {
    margin: 0;
  }
}

.Toastify {
  //height: 3em !important;
  //line-height: 3em !important;

  .Toastify__toast-container {
    //margin-top: 15px !important;
    position: static !important;
    left: auto !important;
    bottom: auto !important;
    width: 100% !important;
    transform: none !important;
    padding: 0 !important;
    
    height: 3em !important;
    line-height: 3em !important;
    display: block !important;
    
    .Toastify__toast {
      box-shadow: none !important;
      margin-bottom: 0 !important;
      border-radius: 0 !important;
      font-family: $font-family-1 !important;
      cursor: default !important;
      background: $green !important;
      height: 3em !important;
      line-height: 3em !important;
      min-height: auto !important;
      padding: 0 !important;
  
      .Toastify__toast-body {
        color: $dark_green !important;
        font-weight: 400 !important;
        display: block !important;
        padding: 0 !important;
        
        div {
          height: 3em !important;
          line-height: 3em !important;

          &:after {
            content: "\f058";
            font-family: "Font Awesome 5 Pro";
            font-weight: 900;
            margin-left: 0.5rem;
          }
        }
      }
  
      .Toastify__toast-icon,
      button { display: none !important;}
    }
  }
}